<!-- Banner Area -->
<div class="banner-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="banner-img-two">
                    <div class="banner-images">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                    <div class="banner-half-circle"></div>
                    <div class="dots">
                        <img src="assets/img/home-two/dots2.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-content banner-two-content">
                    <span>10+ Years Of Experience</span>
                    <h1>Meet Our Experts & Get Better Result & Success</h1>
                    <p>We provide full-cycle software development service encompassing planning, requirements definition, design and prototyping,
                        software development, testing, deployment and application maintenance.</p>
                    <div class="banner-btn">
                        <a routerLink="/contact" class="contact-btn">Contact Us</a>
                        <a routerLink="/" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="banner-two-shape">
        <div class="shape-1"><img src="assets/img/home-two/dots.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/home-two/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/home-two/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/home-two/shape-bottom.png" alt="Images"></div>
    </div>
</div>
<!-- Banner Area End -->

<!-- Service Area -->
<div class="service-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h2>Our Best Consulting Services Make You To Reach Your Goal</h2>
            <p>Are you looking for the best Software Development, Web Design & Development, Mobile App Development and Digital Marketing company in Dallas, Texas? Then you are the right place.</p>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item1.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-balance"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a>Software Development</a></h3>
                        <p>It is a process involving the creation, maintenance of applications, frameworks of software, design, programming, testing, and bug fixing.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item2.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-analytics"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a >Mobile App Development</a></h3>
                        <p>It is the act of developing app suitable for mobile devices that involve writing software for small, wireless computing devices.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item3.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-sort"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a >Website Development</a></h3>
                        <p>It involves building and maintaining the websites; it makes the website look great, works quickly with firm user experience.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item4.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-people"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a >IT Consulting</a></h3>
                        <p>Our strategic IT consulting will help you automate and digitalise operations, optimise the software portfolio, and implement the latest technologies.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item5.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-briefcase-1"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a >UI / UX  Designing</a></h3>
                        <p>It increases the user experience and customer satisfaction, which ultimately increases the number of customers, resulting in the growth of the business.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a ><img src="assets/img/services/services-item6.jpg" alt="Images"></a>
                        <a href="#" class="service-item-icon"><i class="flaticon-chess-pieces"></i></a>
                        <a  class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    <div class="content">
                        <h3><a >Digital Marketing</a></h3>
                        <p>It is a strategy that uses multiple channels to attract engage and convert customers online.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Service Area End -->

<!-- Video Area -->
<div class="video-area-two ptb-100">
    <div class="container">
        <div class="video-btn-two">
            <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="play-on-btn popup-btn"><i class="flaticon-play-button"></i></a>
        </div>
        <div class="video-content-two">
            <div class="section-title">
                <h2>Explore Us Watch This Full Video and Know About Us</h2>
            </div>
        </div>
    </div>
</div>
<!-- Video Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We Are Standing With <b>10+</b> Years Of Experience</h2>
                    <h3>Trusted And Effective IT Consulting Service Provider </h3>
                    <p>Prohance Technologies LLC is a fast-growing IT Company that provides holistic IT solutions to businesses across geographies We offer IT expertise in software, website, and application (iOS & Android) development; UI/UX design; and digital marketing. We are situated at around 12 service locations, serving businesses belonging to various sectors. So long, we have forayed into healthcare, food services, travel & tourism, logistic & shipping, e-commerce, banking, technology, manufacturing, and IT-enabled education service industries so far.</p>
                    <p>Currently, we have a team of highly skilled IT professionals that work round the clock to ensure Techasoft stays true to its offerings & commitments. Our team of experienced experts includes software developers, web developers, designers, and digital marketing experts.</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>5+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>10+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>50+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/about" class="learn-btn">Learn More</a>
                        <a routerLink="/contact" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-img-2">
                    <img src="assets/img/about/about-img3.jpg" alt="Images">

                    <div class="about-img-small-2">
                        <img src="assets/img/about/about-img2.jpg" alt="Images">
                    </div>

                    <div class="about-dots">
                        <img src="assets/img/about/about-dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Industries Area -->
<div class="project-area pb-70">
    <div class="container">
        <div class="project-title-two">
            <div class="section-title">
                <span>Industries</span>
                <h2>Some Of the Industries Where We Helped</h2>
                <p>Prohance Technologies design technology solutions and services that are closely aligned with the needs of the industry.</p>
            </div>
        </div>
        
        <div class="tab project-tab text-center">
            <ul class="tabs">
                <li><a href="#">Insurance</a></li>
                <li><a href="#">Travel,Transportation & Hospitality</a></li>
                <li><a href="#">Banking and Financial</a></li>
                <li><a href="#">Healthcare & Life Sciences</a></li>
                <li><a href="#">Media & Entertainment</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Insurance</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Travel,Transportation & Hospitality</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Banking and Financial</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a ><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Healthcare & Life Sciences</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Media & Entertainment</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Hi-tech & Manufacturing</a></h3>
                                        <div class="content">
                                            <p></p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Insurance</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Travel,Transportation & Hospitality</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Banking and Financial</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a ><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Healthcare & Life Sciences</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Media & Entertainment</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Hi-tech & Manufacturing</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Insurance</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Travel,Transportation & Hospitality</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Banking and Financial</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a ><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Healthcare & Life Sciences</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Media & Entertainment</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Hi-tech & Manufacturing</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Insurance</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Travel,Transportation & Hospitality</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Banking and Financial</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a ><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Healthcare & Life Sciences</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Media & Entertainment</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Hi-tech & Manufacturing</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Insurance</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Travel,Transportation & Hospitality</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Banking and Financial</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a ><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Healthcare & Life Sciences</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Media & Entertainment</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a ><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a >Hi-tech & Manufacturing</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a  class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Industries Area End -->

<!-- Guidelines Area -->
<div class="guidelines-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 pl-0">
                <div class="guidelines-img">
                    <img src="assets/img/guidelines-img.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12 pr-0">
                <div class="guidelines-content-bg">
                    <div class="guidelines-content-two">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>We, at Prohance Technologies, believe in working in partnerships with our clients. We consistently interact and work together to create intelligent solutions that meet our client’s evolving business demands. And thus, provide quick turnarounds by following this collaborative style of working. We have been continuously bridging the gap between business and technology.</p>
                        <!-- <div class="signature"><img src="assets/img/signature2.png" alt="Images"></div> -->
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Guidelines Area End -->

<!-- Choose Area -->
<div class="choose-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="choose-content">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>Trusted Consulting Company To Give You Right Guideline</h2>
                        <p>We hire only the best to keep ourselves amongst the top software consulting companies. Our employees are then constantly trained and certified in the latest tools and technology trends.</p>
                        <a routerLink="/contact" class="default-btn">Book A Service</a>
                    </div>

                    <div class="row border-top">
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-sort"></i>
                                <div class="content">
                                    <h3>Digital Services</h3>
                                    <p>We partner with you in building a robust Digital Backbone.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>AI, Data & Insights</h3>
                                    <p>AI, Data & Insights is our paradigm to help you achieve your goal of  business outcomes through Data.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-analytics"></i>
                                <div class="content">
                                    <h3>Digital Process Automation</h3>
                                    <p>Leveraging technology to automate and optimize activities throughout value chains and vital support functions”</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-chess-pieces"></i>
                                <div class="content">
                                    <h3>Cloud and Infrastructure</h3>
                                    <p>We combine domain and technology with engineering practices to help you evolve and future-proof your multi-cloud environment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img-2">
                    <img src="assets/img/choose/choose-img4.jpg" alt="Images">
                    <div class="choose-dots">
                        <img src="assets/img/choose/choose-dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Lovely Clients Get Helped From Our Company</h2>
            <p>Our customers love us! Read what Our customers Say ?</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Thomas Smith</h3>
                <span>CEO Of LTD Company</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Working With The Prohance Tech Has Been A Most Pleasant Experiance! Their Didication And Passion For What They Do Is Exemplied By Their Unrivealed Customer Support And Attentiveness To The Specific Needs Of Our Business.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>Sinthy Alina</h3>
                <span>CEO Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Working With The Prohance Tech Has Been A Most Pleasant Experiance! Their Didication And Passion For What They Do Is Exemplied By Their Unrivealed Customer Support And Attentiveness To The Specific Needs Of Our Business.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>James Nilson</h3>
                <span>Manager Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Working With The Prohance Tech Has Been A Most Pleasant Experiance! Their Didication And Passion For What They Do Is Exemplied By Their Unrivealed Customer Support And Attentiveness To The Specific Needs Of Our Business.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>It is important to stay agile, innovative and open minded to grow your business in any year, but with the uncertainty of the past year it has never been more important to embody these characteristics for the success and survival of your business

                </p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Whether you’re a candidate looking for a position or a potential client looking for talent, you can contact Prohance Technologies directly by filling out the form below.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->