<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-location-plus'></i>
                                </div>
                                <a href="#" target="_blank">1327 Empire Central Dr, Dallas, TX, 75247</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info@prohancetech.com">info@prohancetech.com</a>
                            </li>

                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-user'></i>
                                </div>
                                <a routerLink="/employee-portal" *ngIf="!loginDisplay">Employee Portal</a>
                                <a href="#" *ngIf="loginDisplay">{{displayName.substring(0, 6)}}</a>
                                <div class="logout-icon" *ngIf="loginDisplay" >
                                    <i class='bx bx-power-off' style="font-size: 27px;"(click)="logout()"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item"><a href="javascript:void(0)" routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle">Home</a>

                    </li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>
                            <li class="nav-item"><a routerLink="/apply-now" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Apply Now</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Projects <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li>
                                    <li class="nav-item"><a  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects Details</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study</a></li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                            <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
                            <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>
                            <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
                            <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>
                            <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                        </ul>
                    </li> -->
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/services-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>

                    </li>

                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/projects" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Industries</a></li>
                    <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>

                    </li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">About Us</a></li>

                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/services-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a>

                    </li>

                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/projects" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Industries</a></li>

                    
                    <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Careers</a></li>
                </ul>
                <div class="others-options d-flex align-items-center">
                    <div class="option-item">
                        <i class='search-btn bx bx-search'></i>
                        <i class='close-btn bx bx-x'></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                        </div>	
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->