import { Component, Inject, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    loginDisplay = false;
    displayName = ""
    private readonly _destroying$ = new Subject<void>();
    



    constructor(  
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    ) { }

    ngOnInit(): void {
                /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */
                 this.msalBroadcastService.inProgress$
                 .pipe(
                   filter((status: InteractionStatus) => status === InteractionStatus.None),
                   takeUntil(this._destroying$)
                 )
                 .subscribe(() => {
                   this.setLoginDisplay();
                   this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)

                 });
    }


    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    setLoginDisplay() {
         this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    getClaims(claims: any) {
     
        this.displayName = claims['name'] 
    
    }


    logout() {
        this.authService.logout();
      }

// unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}