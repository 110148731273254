<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/everify_logo.png" alt="Images" width="200"></a>
                    </div>
                    <p>An E-Verified Company Copyright © 2022 Prohance Technologies All Rights Reserved.</p>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-2">Services</a></li>
                        <li><a routerLink="/projects">Industries</a></li>
                        <li><a routerLink="/contact">Contacts</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-time'></i>
                            Mon - Fri: 8.00am - 5.00pm
                        </li>
                        <li>
                            <i class='bx bx-location-plus'></i>
                            1327 Empire Central Dr, Suite 260-F, Dallas, TX, 75247
                        </li>
                    
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:info@gloz.com">info@prohancetech.com</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">

                    <div class="newsletter-area">
                        <!-- <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL">
                            <button class="default-btn" type="submit">Subscribe  </button>
                        </form> -->
                        <img src="assets/img/microsoft-partner.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Copyright @2022 Prohance Technologies LLC. All Rights Reserved</p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->