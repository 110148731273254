<div class="container mb-5">
    <div class="row">

        <div class="col mr-2">
            <div class="card text-center" style="width: 18rem;">
                <a href="#">
                    <i class='bx bxs-user-detail' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">Employee Profile</h5>
                </div>
            </div>
        </div>

        <div class="col mr-2">
            <div class="card text-center" style="width: 18rem;">
                <a href="#">
                    <i class='bx bxs-calendar' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">Submit Timesheet</h5>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card text-center" style="width: 18rem;">
                <a href="https://app.gusto.com/login" target="_blank">
                    <i class='bx bxs-wallet' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">My Pay</h5>
                </div>
            </div>
        </div>

    </div>


    <div class="row mt-5">
        <div class="col">
            <div class="card text-center" style="width: 18rem;">
                <a href="#">
                    <i class='bx bxs-calendar-edit' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">Apply Leave</h5>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card text-center" style="width: 18rem;">
                <a href="#">
                    <i class='bx bxs-paper-plane' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">Service Request</h5>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card text-center" style="width: 18rem;">
                <a href="#">
                    <i class='bx bxs-book-reader' style="font-size:100px;"></i>
                </a>
                <div class="card-body">
                    <h5 class="card-title">Learning Center</h5>
                </div>
            </div>
        </div>

    </div>

</div>