<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img about-img-before">
                    <img src="assets/img/about/about-img1.jpg" alt="Images">

                    <div class="about-img-small">
                        <img src="assets/img/about/about-img2.jpg" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We Are Standing With <b>10+</b> Years Of Experience</h2>
                    <h3>Trusted And Effective Business Consulting Service Provider </h3>
                    <p>Prohance Technologies LLC is a fast-growing IT Company that provides holistic IT solutions to businesses across geographies We offer IT expertise in software, website, and application (iOS & Android) development; UI/UX design; and digital marketing. We are situated at around 12 service locations, serving businesses belonging to various sectors. So long, we have forayed into healthcare, food services, travel & tourism, logistic & shipping, e-commerce, banking, technology, manufacturing, and IT-enabled education service industries so far.</p>
                    <p>Currently, we have a team of highly skilled IT professionals that work round the clock to ensure Techasoft stays true to its offerings & commitments. Our team of experienced experts includes software developers, web developers, designers, and digital marketing experts</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>5+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>5+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>50+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/contact" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>Trusted Consulting Company To Give You Right Guideline</h2>
                        <p>We hire only the best to keep ourselves amongst the top software consulting companies. Our employees are then constantly trained and certified in the latest tools and technology trends.</p>
                        <a routerLink="/contact" class="book-btn">Book A Service</a>
                    </div>

                    <div class="row border-top">
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-sort"></i>
                                <div class="content">
                                    <h3>Fast Service</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Time Saving</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-analytics"></i>
                                <div class="content">
                                    <h3>Business Planning</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-chess-pieces"></i>
                                <div class="content">
                                    <h3>Experienced Team</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img-2">
                    <img src="assets/img/choose/choose-img4.jpg" alt="Images">

                    <div class="choose-dots">
                        <img src="assets/img/choose/choose-dots.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Lovely Clients Get Helped From Our Company</h2>
            <p>Our customers love us! Read what Our customers Say ?</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Thomas Smith</h3>
                <span>CEO Of LTD Company</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Working With The Prohance Tech Has Been A Most Pleasant Experiance! Their Didication And Passion For What They Do Is Exemplied By Their Unrivealed Customer Support And Attentiveness To The Specific Needs Of Our Business.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>Sinthy Alina</h3>
                <span>CEO Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Working With The Prohance Tech Has Been A Most Pleasant Experiance! Their Didication And Passion For What They Do Is Exemplied By Their Unrivealed Customer Support And Attentiveness To The Specific Needs Of Our Business.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>James Nilson</h3>
                <span>Manager Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div>
            </div>
        </div>
    </div>

    <div class="client-shape">
        <div class="shape1"><img src="assets/img/shape/shape8.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>It is important to stay agile, innovative and open minded to grow your business in any year, but with the uncertainty of the past year it has never been more important to embody these characteristics for the success and survival of your business</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Whether you’re a candidate looking for a position or a potential client looking for talent, you can contact Prohance Technologies directly by filling out the form below.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->