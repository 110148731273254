<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
       
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Address</h3>
                        <p>1327 Empire Central Dr, Suite 260-F, Dallas, TX, 75247</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:info@prohancetech.com">info@prohancetech.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>It is important to stay agile, innovative and open minded to grow your business in any year, but with the uncertainty of the past year it has never been more important to embody these characteristics for the success and survival of your business</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                            <p>Whether you’re a candidate looking for a position or a potential client looking for talent, you can contact Prohance Technologies directly by filling out the form below.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe src="https://maps.google.com/maps?q=1327%20Empire%20Central%20Dr,%20Suite%20260-F,%20Dallas,%20TX,%2075247&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
</div>
<!-- Map Area End -->

