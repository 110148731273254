<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/img/career-banner.jpeg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/career-banner.jpeg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="assets/img/career-banner.jpeg" class="d-block w-100" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>


  <div class="text-center mt-5 mb-5">
    
        <h2>Welcome to Prohance Technologies Careers</h2>

        <p>Do you want to take charge of your career?</p>

        <p>At Prohance Tech, we give you the right opportunity and help you achieve your career goals. We grow with you!</p>

        <div>
          <p>Please reach out to us at &nbsp;<a href="mailto:careers@prohancetech.com">careers@prohancetech.com</a></p>
          <a href="mailto:careers@prohancetech.com" class="btn btn-warning">Apply Now</a>
        </div>

  </div>